import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Carousel, Icon, Card } from "antd";
import "intersection-observer";
import Observer from "@researchgate/react-intersection-observer";
import TextWrapper from "../components/TextWrapper";
import Latin from "../components/Latin";
import { LayoutContext } from "../Layout";
import "./Home.css";

const perVisible = 0.8;

export default class Home extends Component {
  static contextType = LayoutContext;

  handleHeaderVisibilityChange = ({ isIntersecting, intersectionRatio }) => {
    const showFixedHeader = intersectionRatio < perVisible;
    this.context.setFixedHeaderVisibility(showFixedHeader);
  };

  componentWillMount() {
    this.context.setHasAlternateHeader(true);
  }

  componentWillUnmount() {
    this.context.setHasAlternateHeader(false);
    this.context.setFixedHeaderVisibility(true);
  }

  render() {
    return (
      <div>
        <Observer
          onChange={this.handleHeaderVisibilityChange}
          threshold={perVisible}
        >
          <Carousel autoplay={true} autoplaySpeed={5000} effect="fade">
            <div>
              <div
                className="bgDiv"
                style={{
                  backgroundImage:
                    "url(/images/artem-gavrysh-698535-unsplash-scale.jpg)"
                }}
              >
                <PromoText />
              </div>
            </div>
            <div>
              <div
                className="bgDiv"
                style={{
                  backgroundImage:
                    "url(/images/ashim-d-silva-89336-unsplash-scale.jpg)"
                }}
              >
                <PromoText />
              </div>
            </div>
            <div>
              <div
                className="bgDiv"
                style={{
                  backgroundImage:
                    "url(/images/clark-street-mercantile-33917-unsplash-scale.jpg)"
                }}
              >
                <PromoText />
              </div>
            </div>
            <div>
              <div
                className="bgDiv"
                style={{
                  backgroundImage:
                    "url(/images/clark-street-mercantile-33931-unsplash-scale.jpg)"
                }}
              >
                <PromoText />
              </div>
            </div>
            <div>
              <div
                className="bgDiv"
                style={{
                  backgroundImage:
                    "url(/images/jason-briscoe-156649-unsplash-scale.jpg)"
                }}
              >
                <PromoText />
              </div>
            </div>
          </Carousel>
        </Observer>
        <TextWrapper>
          <h2>How It Works</h2>
          <p>
            Turing Retail ensure you don't lose sales when your're out-of-stock.
            By doing stuff in the patent application, we can increase conversion
            rates and improve your inventory accuracy.
          </p>
          <br />
          <br />
          <h2>Results</h2>
          <div className="flex-grid">
            <div className="flex-grid-thirds">
              <Card style={{ margin: "16px 0px" }}>
                <Card.Meta
                  description={
                    <>
                      <h4>Customer Satisfaction</h4>
                      <p>
                        Ensures customers are happy with their trip and keep
                        coming back.
                      </p>
                    </>
                  }
                  avatar={
                    <Icon
                      type="smile"
                      theme="twoTone"
                      twoToneColor="#F8DE7E"
                      style={{ fontSize: "32px" }}
                    />
                  }
                />
              </Card>
            </div>
            <div className="flex-grid-thirds">
              <Card style={{ margin: "16px 0px" }}>
                <Card.Meta
                  description={
                    <>
                      <h4>Increased Sales</h4>
                      <p>
                        Capture the sale instead of customers buying somewhere
                        else.
                      </p>
                    </>
                  }
                  avatar={
                    <Icon
                      type="rise"
                      style={{ fontSize: "32px", color: "#800000" }}
                    />
                  }
                />
              </Card>
            </div>
            <div className="flex-grid-thirds">
              <Card style={{ margin: "16px 0px" }}>
                <Card.Meta
                  description={
                    <>
                      <h4>Trade Funds</h4>
                      <p>
                        Give your vendors an opportunity to interact with your
                        customers and drive additional trade funds.
                      </p>
                    </>
                  }
                  avatar={
                    <Icon
                      type="dollar"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                      style={{ fontSize: "32px" }}
                    />
                  }
                />
              </Card>
            </div>
          </div>
        </TextWrapper>
      </div>
    );
  }
}

const PromoText = () => (
  <>
    <div
      style={{
        opacity: 1,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 10
      }}
    >
      <div style={{ maxWidth: 960, height: "35px", margin: "auto" }}>
        <div>
          &nbsp;
          <span style={{ float: "right" }}>
            <Link to="/">
              <img src="/images/logo_big_noBG.png" alt="Turing Retail" />
            </Link>
          </span>
        </div>
      </div>
    </div>
    <div
      style={{
        opacity: 1,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 10
      }}
    >
      <div style={{ maxWidth: 960, height: "35px", margin: "auto" }}>
        <div className="text">
          <div style={{ margin: "auto" }}>
            <h1>Don't Lose the Sale</h1>
            <h2>
              We help stop lost sales and improve your inventory accuracy.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </>
);
