import React, { Component } from "react";

export default class TextWrapper extends Component {
  render() {
    return (
      <div className="textContainerOuter">
        <div className="textContainer">{this.props.children}</div>
      </div>
    );
  }
}
