import React, { Component } from "react";
import { Icon } from "antd";
import TextWrapper from "../components/TextWrapper";

export class FourZeroFour extends Component {
  render() {
    return (
      <TextWrapper>
        <div>
          <h1>
            <Icon
              type="exclamation-circle"
              theme="twoTone"
              twoToneColor="#FF0000"
            />{" "}
            404
          </h1>
          <p>Hmm... the page you were looking for could not be found.</p>
        </div>
      </TextWrapper>
    );
  }
}
