import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Media from "react-media";
import "./Layout.css";
import { Layout as LayoutAntd, Button, Drawer, Menu, Row, Col } from "antd";

export const LayoutContext = React.createContext();

const { Header, Footer, Content } = LayoutAntd;

const mobileNavSize = "600";

export default class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideNavigation: false,
      fixedHeaderVisibility: true,
      hasAlternateHeader: false
    };
    this.setFixedHeaderVisibility = this.setFixedHeaderVisibility.bind(this);
    this.toggleSideNavigation = this.toggleSideNavigation.bind(this);
    this.closeSideNavigation = this.closeSideNavigation.bind(this);
  }

  setFixedHeaderVisibility(showFixedHeader) {
    this.setState({
      fixedHeaderVisibility: showFixedHeader
    });
  }

  setHasAlternateHeader = hasAlternateHeader => {
    this.setState({
      hasAlternateHeader
    });
  };

  toggleSideNavigation() {
    this.setState({ showSideNavigation: !this.state.showSideNavigation });
  }

  closeSideNavigation() {
    this.setState({ showSideNavigation: false });
  }

  render() {
    return (
      <LayoutContext.Provider
        value={{
          fixedHeaderVisibility: this.state.fixedHeaderVisibility,
          setFixedHeaderVisibility: this.setFixedHeaderVisibility,
          setHasAlternateHeader: this.setHasAlternateHeader
        }}
      >
        <LayoutAntd>
          {this.state.fixedHeaderVisibility && (
            <Header
              style={{
                position: "fixed",
                zIndex: 1,
                width: "100%",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 5px"
              }}
            >
              <div className="headFootContainer">
                <div className="logo">
                  <Link to="/">
                    <img src="/images/logo.png" alt="Turing Retail" />
                  </Link>
                </div>
                <div style={{ textAlign: "right" }}>
                  <Media query={`(max-width: ${mobileNavSize}px)`}>
                    {matches =>
                      matches ? (
                        <Button
                          icon="menu-fold"
                          size="large"
                          ghost
                          style={{ textAlign: "right" }}
                          onClick={this.toggleSideNavigation}
                        />
                      ) : (
                        <MenuItems isVertical={false} />
                      )
                    }
                  </Media>
                </div>
              </div>
            </Header>
          )}
          <LayoutAntd>
            <Content
              style={{
                marginTop: this.state.hasAlternateHeader ? 0 : 64,
                minHeight: 600,
                background: "#fff"
              }}
            >
              <Drawer
                placement="left"
                visible={this.state.showSideNavigation}
                onClose={this.closeSideNavigation}
                closable={false}
                style={{ background: "#1b1c1d", height: "100%" }}
              >
                <MenuItems isVertical={true} />
              </Drawer>
              {this.props.children}
            </Content>
          </LayoutAntd>
          <Footer style={{ color: "#fff", lineHeight: "2em" }}>
            <div className="headFootContainer">
              <Row type="flex" justify="start">
                <Col
                  span={6}
                  style={{
                    padding: "10px 0px",
                    maxWidth: "200px",
                    minWidth: "200px"
                  }}
                >
                  {this.state.headerVisibility}
                  <b>Outline</b>
                  <br />
                  <Link to="/about">About Us</Link>
                  <br />
                  <Link to="/privacy">Privacy Policy</Link>
                </Col>
                <Col
                  span={12}
                  style={{
                    padding: "10px 0px",
                    maxWidth: "450px",
                    minWidth: "250px"
                  }}
                >
                  <b>Turing Retail</b>
                  <br />
                  Making algorithmic decisions that are indistinguishable from
                  ones humans make
                </Col>
              </Row>
            </div>
          </Footer>
        </LayoutAntd>
      </LayoutContext.Provider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node
};

const MenuItems = ({ isVertical }) => {
  return (
    <Menu
      theme="dark"
      mode={isVertical ? "vertical" : "horizontal"}
      style={{ lineHeight: "64px" }}
    >
      <Menu.Item key="1">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/about">About Us</Link>
      </Menu.Item>
    </Menu>
  );
};
