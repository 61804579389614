import React, { Component } from "react";
import { Avatar, Icon, List, Card } from "antd";
import TextWrapper from "../components/TextWrapper";
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div>
        <TextWrapper>
          <h1>About Us</h1>
          <h2>Mission</h2>
          <p>
            Turing Retail exist to provide incredible, individualized retailing
            experiences at scale by leveraging artificial intelligence to make
            decisions that humans would make for an single person.
          </p>
          <br />
          <br />
          <h2>Values</h2>
          <List>
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type="search" style={{ fontSize: "20px" }} />}
                title="Curiosity"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type="save" style={{ fontSize: "20px" }} />}
                title="Commitment"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type="bulb" style={{ fontSize: "20px" }} />}
                title="Intelligence"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type="retweet" style={{ fontSize: "20px" }} />}
                title="Transparency"
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Icon type="swap" style={{ fontSize: "20px" }} />}
                title="Adaptability"
              />
            </List.Item>
          </List>
          <br />
          <br />
          <h2>Team</h2>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={data}
            renderItem={item => (
              <List.Item>
                <Card>
                  <Card.Meta
                    title={item.title}
                    avatar={
                      <Avatar src={item.image} shape="square" size={128} />
                    }
                    description={item.bio}
                  />
                </Card>
              </List.Item>
            )}
          />
        </TextWrapper>
      </div>
    );
  }
}

const data = [
  {
    title: "Richard Wright, CEO",
    bio:
      "Richard Wright is a proven leader in driving strategic business transformation. He’s lead numerous top 50 US retailers through changes in their merchandising and supply chain process that have produced significant revenue growth and cost reductions. As part of the Predictx executive team, he played a key part in growing the company at an over 33% CAGR which lead to it’s acquisition at a $150m valuation. Richard combines deep intellect, focus, empathy, and a passion for the future to solve hard problems and forge the future of retail.",
    image: "/images/wright_richard.png"
  }
];
