import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Views/Home";
import About from "./Views/About";
import Privacy from "./Views/Privacy";
import { FourZeroFour } from "./Views/Error";
import GA from "./components/GoogleAnalytics";

const LayoutWithRouter = withRouter(Layout);

class App extends Component {
  render() {
    return (
      <Router>
        <Scroller>
          <LayoutWithRouter>
            {GA.init() && <GA.RouteTracker />}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/index.html" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/privacy" component={Privacy} />
              <Route component={FourZeroFour} />
            </Switch>
          </LayoutWithRouter>
        </Scroller>
      </Router>
    );
  }
}

export default App;

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}
const Scroller = withRouter(ScrollToTop);
